import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function SkinConditions() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Skin conditions</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/illnesses">
Illnesses </Link> </li>
                  <li> Skin conditions  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Skin conditions</h1>
                <p>
            Insect bites, rashes, sunburn and everything in between. We can identify and diagnose most skin conditions and provide treatment to get you the relief you need.
                </p>
                <h3 className="mt-5">Common skin conditions we treat.</h3>
                <p>RH Medical is here to help relieve itchy, painful, and infected skin. If you or your child is experiencing skin irritation, walk right into any location with no appointment needed. You will be evaluated quickly by a board-certified physician and get relief for: </p>
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Allergic reactions and hives 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Chickenpox 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Eczema
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Minor burns  
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Poison ivy
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Rashes  
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Skin infections
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Skin irritation, including dry, cracked, itchy or peeling skin
                        </li>
                          <li>
                          <i className="flaticon-check-mark"></i> Skin fungus
                        </li>
                          <li>
                          <i className="flaticon-check-mark"></i> Sunburn, sun poisoning and heat rash
                        </li>
                          <li>
                          <i className="flaticon-check-mark"></i> <Link className="read-more-btn-service" to={'/services/injuries/tick-bites'}>Tick bites</Link> and insect bites
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Help in a pinch. When to use urgent care.</h2>
                <p>
           When you have an unexpected injury, or even recognize the onset of cold or flu symptoms outside usual doctor’s office hours, here's how to determine where you should get assessed.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SkinConditions;



