import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function UtiTesting() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>UTI testing</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/womens-health">
Women’s health </Link> </li>
                  <li> UTI testing  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>UTI testing</h1>
                <p>
             Urinary tract infections (UTIs) are common infections that occur when bacteria enters the urinary tract, and are typically associated with a feeling of burning, pain or irritation when you urinate. Our team of medical experts at RH Medical provide comprehensive same-day UTI services, including testing and treatment.
                </p>
                <h3 className="mt-5">UTI testing and treatment at RH Medical.</h3>
                <p>A urinary tract infection (UTI) happens when bacteria from the skin of the genitalia or rectal area finds its way into the urinary tract. The bacteria multiply in the urethra or bladder causing an infection. Symptoms of a UTI include frequent urination, burning while urinating or having the urge to urinate with only little urine coming out. Women may also have pain in the pelvic area.</p>
               <p>For people of all ages, risk factors of contracting a UTI include sexual activity and poor hygiene. Men over the age of 50 are at increased risk for UTI because of enlarged prostate. Get UTI testing and treatment at the first sign of possible UTI symptoms, as waiting could lead to complications including a serious kidney infection called pyelonephritis. At RH Medical, we provide urgent care UTI services to quickly diagnose and treat your UTI in a single visit.</p>
                <h3 className="mt-5">Where to go for UTI treatment?</h3> 
                <p>For immediate, fast, and comprehensive UTI testing and care, walk into one of our urgent care clinics today.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Do I have a UTI?</h2>
                <p>
             Seek treatment at the first signs of these common symptoms of a Urinary Tract Infection (UTI).
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UtiTesting;



