import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function TravelVaccinations() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Travel vaccinations and immunizations</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/vaccinations">

Vaccinations  </Link> </li>
                  <li> Travel vaccinations and immunizations  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Travel vaccinations and immunizations</h1>
                <p>
           Traveling overseas for work can be thrilling—and it also can be stressful. When you don’t have much time to plan, it can be challenging to just to put together a packing list, let alone research which travel vaccinations you may need to protect against exotic or foreign diseases. The CDC can help you search by country to find out which vaccines you need for your travels, and your nearby RH Medical urgent care can give them to you.
                </p>
                <p>
                  Many vaccine-preventable diseases can be fatal, so planning ahead and protecting yourself with the proper immunization before travel is crucial.
                </p>
                <h3 className="mt-5">Travel vaccines & immunizations available at RH Medical include:</h3>
                <p>Hepatitis A, Hepatitis B, Influenza, Meningococcal (Menactra), Measles/Mumps/Rubella (MMR), Rabies Activate Vaccine, Tetanus/Diphtheria/Pertussis (TDaP), Tetanus, Tuberculin (PPD), Zoster (Shingles), Varivax (aka Varicella/Chicken pox).</p>
                <p>*Be sure to call your local RH Medical to ensure we have a vaccine in stock.</p>
                <h3 className="mt-5">Where to get your travel shots.</h3>
                <p>For immediate, fast travel shots, walk into one of our urgent care clinics today.</p>
              </div>
            </div>

          
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Cold vs. flu.</h2>
                <p>
           It's not always easy to tell the difference between a case of the flu and the common cold based on the symptoms alone. Learn about what your symptoms could mean and how RH Medical can help.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TravelVaccinations;



