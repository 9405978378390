import { Link } from "react-router-dom";

function Preloader() {
  return (
    <>
      <section className="services-area ptb-100 bg-f4f9fd">
        <div className="container">
          <div className="section-title">
            <span>Our Services</span>
            <h2>Our Healthcare Services</h2>
            <p>We help treat a range of symptoms and urgent care needs.</p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box bg-1">
                <div className="icon">
                  <i className="flaticon-cancer"></i>
                </div>
                <h3>
                  <Link to="/services/illnesses">I'm sick</Link>
                </h3>
                <p>
                  When you're not feeling well due to an illness, we'll provide
                  the fast, quality care you need to get back on track.
                </p>

                <Link to="/services/illnesses" className="read-more-btn">
                  Read more <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box bg-2">
                <div className="icon">
                  <i className="flaticon-liver"></i>
                </div>
                <h3>
                  <Link to="/services/injuries">I’m injured</Link>
                </h3>
                <p>
                  Our doctors, medical assistants, and technicians are trained
                  in all aspects of urgent care medicine for both adults and
                  children.
                </p>

                <Link to="/services/injuries" className="read-more-btn">
                  Read more <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-services-box bg-3">
                <div className="icon">
                  <i className="flaticon-kidney"></i>
                </div>
                <h3>
                  <Link to="/services/vaccinations">I need immunizations</Link>
                </h3>
                <p>
                  We offer a wide range of vaccines, from the seasonal flu to
                  travel vaccines, to keep you healthy throughout the year.
                </p>

                <Link to="/services/vaccinations" className="read-more-btn">
                  Read more <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="more-services-btn">
                <Link to="/services" className="btn btn-primary">
                  More Services <i className="flaticon-right-chevron"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Preloader;
