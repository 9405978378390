
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className="footer-area">
        <div className="container">
          {/* <div className="subscribe-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="newsletter-content">
                  <h2>Join Our Newsletter</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <form className="newsletter-form" data-toggle="validator">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="EMAIL"
                    required
                    autoComplete="off"
                  />

                  <button type="submit">
                    Subscribe <i className="fas fa-paper-plane"></i>
                  </button>
                  <div id="validator-newsletter" className="form-result"></div>
                </form>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to="/">
                    <img src="/assets/img/white-logo.png" alt="image" />
                  </Link>
                  <p>
                   Urgent Care for Adults & Pediatrics equipped with onsite Laboratory and X-ray. No Appointment Needed
                  </p>
                </div>

                <ul className="social">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=100063785340628" target={'_blank'}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.instagram.com/rhmedicalurgentcare/" target={'_blank'}>
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Services</h3>

                <ul className="departments-list">
                  <li>
                    <Link to="/services/injuries">Injuries</Link>
                  </li>
                  <li>
                    <Link to="/services/illnesses">Illnesses</Link>
                  </li>
                  <li>
                    <Link to="/services/womens-health">Women's health</Link>
                  </li>
                  <li>
                    <Link to="/services/pediatric-care">Pediatric care</Link>
                  </li>
                  <li>
                    <Link to="/services/x-rays">X-rays</Link>
                  </li>
                  <li>
                    <Link to="/services/vaccinations">Vaccinations</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Other Links</h3>

                <ul className="links-list">
                  <li>
                    <Link to="/about">About RH Medical</Link>
                  </li>
                  {/* <li>
                    <Link to="/health-and-wellness">Health & wellness</Link>
                  </li>
                  <li>
                    <Link to="/in-the-know">In the Know</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li> */}
                   <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Opening Hours</h3>

                <ul className="opening-hours">
                  <li>
                    Monday <span>9:00 AM - 5:30 PM</span>
                  </li>
                   <li>
                    Tuesday <span>9:00 AM - 5:30 PM</span>
                  </li>
                  <li>
                    Wednesday <span>9:00 AM - 5:30 PM</span>
                  </li>
                  <li>
                    Thursday <span>9:00 AM - 5:30 PM</span>
                  </li>
                  <li>
                    Friday <span>9:00 AM - 5:30 PM</span>
                  </li>
                  <li>
                    Saturday <span>Closed</span>
                  </li>
                   <li>
                    Sunday <span>Closed</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="container-md mt-3">
            <div className="row">
            <div className="col-md-9 p-0 m-auto">
          <ul className="footer-legal-link ps-0 d-md-flex" >
                                <li className="nav-item mt-3"><Link to="/privacy">Privacy Policy</Link></li>
                                <li className="nav-item mt-3"><Link to="http://www.citymd.com/sites/default/files/2022-06/final_no-surprises-act-disclosure-notice_5122022.pdf" target={"_blank"}>No Surprises Act</Link></li>
                                <li className="nav-item mt-3"><Link to="http://www.citymd.com/sites/default/files/2022-09/CityMD-Notice-of-Privacy-Practices.pdf"  target={"_blank"}>Notice of Privacy Practices</Link></li>
                   <li className="nav-item mt-3"><Link target={"_blank"} to="http://www.citymd.com/sites/default/files/2022-06/citymd-nondiscrimination-ny-nj.pdf">Non-discrimination Statement</Link></li>             
                  <li className="nav-item mt-3"><Link to="http://www.citymd.com/sites/default/files/2022-09/notice-of-right-to-gfe-final-v40.pdf" target={"_blank"}>Notice of Right to Good Faith Estimate </Link></li>
            </ul>
            </div>
            </div>
            </div> */}

          <div className="copyright-area">
            <p>
              Developed by  
              <a href="https://Vibrantprintshop.com/" target="_blank" className="text-decoration-underline ms-1">
                vibrantprintshop.com
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
