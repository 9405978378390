import { Helmet } from "react-helmet-async";

function Faqs() {
  return (
    <>
        <Helmet>
        <script src="/assets/js/faq.js" />
      </Helmet>
     <section className="faq-area pb-5 mb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="faq-image">
                <img src="assets/img/faq-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="faq-accordion">
                <span className="sub-title">Frequently Asked Questions</span>
                <h2>Get Every Single Answers There if you want</h2>

                <ul className="accordion">
                  <li className="accordion-item">
                    <a className="accordion-title active cursor-pointer" href={void(0)} >
                      <i className="fas fa-plus"></i>
                      My physician is a faculty member—what does that mean?
                    </a>

                    <p className="accordion-content show">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan.
                    </p>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title cursor-pointer" href={void(0)} >
                      <i className="fas fa-plus"></i>
                      What is an academic medical center?
                    </a>

                    <p className="accordion-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan.
                    </p>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title cursor-pointer" href={void(0)} >
                      <i className="fas fa-plus"></i>
                      What doctor will I see?
                    </a>

                    <p className="accordion-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan.
                    </p>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title cursor-pointer" href={void(0)} >
                      <i className="fas fa-plus"></i>
                      What is a medical student?
                    </a>

                    <p className="accordion-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan.
                    </p>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title cursor-pointer" href={void(0)} >
                      <i className="fas fa-plus"></i>
                      How are residents supervised?
                    </a>

                    <p className="accordion-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commodo viverra maecenas accumsan.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faqs;
