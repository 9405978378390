import { Helmet } from "react-helmet-async";
import Odometer from 'react-odometerjs';
import "odometer/themes/odometer-theme-default.css";
import { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
function FunFacts() {
 const [view, setView] = useState(false);
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setView(true);
    }
  };
  
  return (
    <>
      <Helmet>
        {/* <script src="/assets/js/count.js" /> */}
      </Helmet>
     <section className="fun-facts-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="single-fun-facts">
                <div className="icon">
                  <i className="flaticon-doctor-1"></i>
                </div>
                <h3>
                    <VisibilitySensor
          onChange={onVisibilityChange}
          offset={{
            top: 500
          }}
          delayedCall
                  >
                    <Odometer value={view ? 540 : 0}
      theme="default"   />
                    
        </VisibilitySensor>
                  <span className="optional-icon">+</span>
                </h3>
                <p>Expert Doctors</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="single-fun-facts">
                <div className="icon">
                  <i className="flaticon-light-bulb"></i>
                </div>
                <h3>
                  <VisibilitySensor
          onChange={onVisibilityChange}
          offset={{
            top: 500
          }}
          delayedCall
                  >
                    <Odometer value={view ? 899 : 0}
      theme="default"   />
                    
        </VisibilitySensor>
                  <span className="optional-icon">K</span>
                </h3>
                <p>Problem Solve</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="single-fun-facts">
                <div className="icon">
                  <i className="flaticon-science"></i>
                </div>
                <h3>
                  <VisibilitySensor
          onChange={onVisibilityChange}
          offset={{
            top: 500
          }}
          delayedCall
                  >
                    <Odometer value={view ? 100 : 0}
      theme="default"   />
                    
        </VisibilitySensor>
                  <span className="optional-icon">+</span>
                </h3>
                <p>Award Winning</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="single-fun-facts">
                <div className="icon">
                  <i className="flaticon-trophy"></i>
                </div>
                <h3>
                   <VisibilitySensor
          onChange={onVisibilityChange}
          offset={{
            top: 500
          }}
          delayedCall
                  >
                    <Odometer value={view ? 56 : 0}
      theme="default"   />
                    
        </VisibilitySensor>
                  <span className="optional-icon">K</span>
                </h3>
                <p>Experiences</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FunFacts;
