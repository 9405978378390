import { Helmet } from "react-helmet-async";
import AWS_SES from "../../common/mail";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import $ from "jquery";

function Appointment() {
  const selectRef = useRef();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");


    const sendMail = async (name, email,service,phone ) => {
      const html = `<div><strong>Name : </strong> ${name}</div> <br />
                  <div><strong>Email : </strong> ${email}</div> <br />
                  <div><strong>Phone : </strong> ${phone}</div> <br /> 
                  <div><strong>Service : </strong> ${service}</div>
                  `;
    try {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
      body: JSON.stringify({
        recipientEmailId:process.env.REACT_APP_TO_EMAIL,
          subject: "RH Medical Urgent Care Service",
          html:html
        })
    }
    const response = await fetch(process.env.REACT_APP_SEND_EMAIL_URL, config)
    if (response.ok) {
       setLoader(false);
        setMessage("your message has been sent successfully");
        formik.resetForm();
        setTimeout(() => {
          setMessage("");
        }, 5000);
    } else {
        throw "email sent failed"
    }
} catch (error) {
        setMessage(error);
        setLoader(false);
        setTimeout(() => {
          setMessage("");
        }, 5000);
    }
    
           
            }

  // const sendMail = (name, email, service, phone) => {
  //   const html = `<div><strong>Name : </strong> ${name}</div> <br />
  //                 <div><strong>Email : </strong> ${email}</div> <br />
  //                 <div><strong>Service : </strong> ${service}</div> <br /> 
  //                 <div><strong>Phone : </strong> ${phone}</div>
  //                 `;
  //   let params = {
  //     Source: ` RH Medical Urgent Care <${process.env.REACT_APP_SOURCE_EMAIL}>`,
  //     Destination: {
  //       ToAddresses: [process.env.REACT_APP_TO_EMAIL],
  //     },
  //     ReplyToAddresses: [],
  //     Message: {
  //       Body: {
  //         Html: {
  //           Charset: "UTF-8",
  //           Data: html,
  //         },
  //       },
  //       Subject: {
  //         Charset: "UTF-8",
  //         Data: "RH Medical Urgent Care Service",
  //       },
  //     },
  //   };
  //   var sendPromise = AWS_SES.sendEmail(params).promise();
  //   sendPromise
  //     .then((data) => {
  //       setLoader(false);
  //       setMessage("your message has been sent successfully");
  //       selectRef.current = "Injuries";
  //       formik.resetForm();
  //       setTimeout(() => {
  //         setMessage("");
  //       }, 5000);
  //     })
  //     .catch((err) => {
  //       console.error(err, err.stack);
  //       setMessage(err.stack);
  //       setLoader(false);
  //       setTimeout(() => {
  //         setMessage("");
  //       }, 5000);
  //     });
  // };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    service: Yup.string().required("Please select your service"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email"),
    phone: Yup.string().required("Please enter your number"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      service: "Injuries",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      let selected = $(selectRef.current).val();
      sendMail(values?.name, values?.email, selected, values?.phone);
    },
  });

  return (
    <>
      <Helmet>
        <script src="/assets/js/select.js"></script>
      </Helmet>
      <section
        className="appointment-area ptb-100 jarallax mtb-100"
        data-jarallax='{"speed": 0.3}'
      >
        <div className="container">
          <div className="appointment-content">
            {/* <span className="sub-title">Book Appointment</span> */}
            <h2>We are here for you</h2>

            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <div className="icon">
                      <i className="flaticon-user"></i>
                    </div>
                    <label>Your Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      id="name"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik?.values?.name}
                    />
                    {formik?.errors?.name && formik?.touched?.name ? (
                      <div className="help-block text-danger fw-bolder">
                        <ul className="list-unstyled">
                          <li>{formik?.errors?.name}</li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <div className="icon">
                      <i className="flaticon-envelope"></i>
                    </div>
                    <label>Your Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik?.values?.email}
                    />
                    {formik?.errors?.email && formik?.touched?.email ? (
                      <div className="help-block text-danger fw-bolder">
                        <ul className="list-unstyled">
                          <li>{formik?.errors?.email}</li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <div className="icon">
                      <i className="flaticon-support"></i>
                    </div>
                    <label>Select Your Services</label>

                    <select
                      name="service"
                      ref={selectRef}
                      defaultValue={formik?.values?.service}
                    >
                      <option value="Injuries">Injuries</option>
                      <option value="Illnesses">Illnesses</option>
                      <option value={"X-rays"}>X-rays</option>
                      <option value={"Lab tests & screenings"}>
                        Lab tests & screenings
                      </option>
                      <option value={"Vaccinations"}>Vaccinations</option>
                      <option value={"On-the-job injuries"}>
                        On-the-job injuries
                      </option>
                      <option value={"Women’s health"}>Women’s health</option>
                      <option value={"Pediatric care"}>Pediatric care</option>
                      <option value={"Occupational medicine"}>
                        Occupational medicine
                      </option>
                    </select>
                    {formik?.errors?.service && formik?.touched?.service ? (
                      <div className="help-block text-danger fw-bolder">
                        <ul className="list-unstyled">
                          <li>{formik?.errors?.service}</li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <div className="icon">
                      <i className="flaticon-phone-call"></i>
                    </div>
                    <label>Your Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Phone"
                      id="text"
                      name="phone"
                      onChange={formik.handleChange}
                      value={formik?.values?.phone}
                    />
                    {formik?.errors?.phone && formik?.touched?.phone ? (
                      <div className="help-block text-danger fw-bolder">
                        <ul className="list-unstyled">
                          <li>{formik?.errors?.phone}</li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="submit-btn d-flex align-items-center justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loader}
                    >
                      Send Message <i className="flaticon-right-chevron"></i>
                    </button>
                    {loader && (
                      <div
                        className="spinner-border text-danger ms-3"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </div>
                  {message && (
                    <div className="mt-3 text-center h4">{message}</div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Appointment;
