import AWS from 'aws-sdk'

const SES_CONFIG = {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION,
};

const AWS_SES = new AWS.SES(SES_CONFIG);

export default AWS_SES

