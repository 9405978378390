import { Link } from "react-router-dom";

function WoundCare() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Wound care</h2>
               <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/injuries">Injuries</Link> </li>
                  <li> Wound care  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Wound care</h1>
                <p>
                Whether you need a bandage, stitches, or even an X-ray, RH Medical is your one-stop shop.
                </p>
                <h3 className="mt-5 mb-3">Wound care at RH Medical.</h3>
                 <p>
               Our board-certified physicians are here to treat your wounds and injuries, 365 days a year.  When evaluating your wound and developing your treatment plan, our team will also determine whether or not you will need stitches, or an alternative such as a liquid adhesive, to help your wound heal faster and reduce your risk of infection and scarring.
                </p>
                 <p>
                Avoid a trip to the emergency room and be seen quickly at RH Medical for evaluation and treatment of:  
                </p>
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Cuts and scrapes 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Lacerations  
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Minor fractures and dislocations  
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Minor burns 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Punctured skin 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Broken bones 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Splinters
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
 <p>...and more.</p>
            {/* <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_services_list">
                  <h3 className="widget-title">More Services</h3>

                  <ul>
                    <li>
                      <a href="single-services.html" className="active">
                        Cancer Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Liver Transplant{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Kidney Cancer{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Cardiac Arrhythmia{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Dental Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                  </ul>
                </section>

                <section className="widget widget_appointment">
                  <h3 className="widget-title">Get Some Advice?</h3>
                   <SomeAdvice />
                </section>
              </aside>
            </div> */}
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                <h2>Stitches, medical glue or staples.</h2>
                <p>
                When you get a cut that requires medical attention, there are a few methods our team will consider when determining the best way for the wound to heal.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/* <section className="our-mission-area ptb-100 pt-0">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="our-mission-content">
                <h2>Essential steps to find heartburn relief.</h2>
                <p>
                The symptoms of heartburn can be bothersome, especially if you are feeling them frequently.  Here are some lifestyle habits and triggers you should be aware of to alleviate these symptoms.
                </p>
                 <a href="#" className="btn btn-primary mt-4">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="our-mission-image">
                <img src="/assets/img/mission-img1.jpg" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default WoundCare;
