import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function TickBites() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Tick bites</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/injuries">Injuries</Link> </li>
                  <li> Tick bites  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Tick bites</h1>
                <p>
               As the weather gets warmer and we spend more time outdoors, particularly in the woods and high grass areas, we become more susceptible to tick bites. Fortunately, at RH Medical, our doctors are trained to remove ticks and proactively treat potential infections or tick-borne diseases.
                </p>
                <h3 className="mt-5">What to do if you’re bitten by a tick.</h3>
                <p>
              If you suspect you have been bitten by a tick or see a tick on you, the most important thing you should do is see a physician within 48 to 72 hours. Within that time frame, preventative antibiotics could be administered, reducing your chances of getting Lyme disease.
                </p>
                <h3 className="mt-5">Tick removal.</h3>
                <p>
             If you are able to see a doctor right away, it’s best to let a physician remove the tick for you. At RH Medical you will receive proper medical evaluation and we will ensure that there are no foreign bodies remaining, which could lead to infection.
                </p>
                <h3 className="mt-5">Lyme disease.</h3>
                <p>
               The black-legged tick, or ‘deer tick’ is the only tick species that carries Lyme disease - this tick happens to be most prevalent in the Northeast. Tick bites and Lyme disease become more common with warmer temperatures, as we start to see a rise in these cases beginning in April and continuing through July.
                </p>
              </div>
            </div>

          
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Skin conditions.</h2>
                <p>
              Insect bites, rashes, sunburn and everything in between. Find out how RH Medical can diagnose skin conditions and provide treatment to get you the relief you need.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TickBites;



