import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function SeasonalAllergies() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Seasonal allergies</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/illnesses">
Illnesses </Link> </li>
                  <li> Seasonal allergies  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Seasonal allergies</h1>
                <p>
             Did you know 1 in 5 people suffer from seasonal allergies? If you have itchy, watery eyes, excessive sneezing, and symptoms that mirror a mild cold, you may need to see a doctor. Our RH Medical physicians are here to help provide the relief you need this Spring season.
                </p>
                <h3 className="mt-5">What are allergies?</h3>
                <p>Allergies occur when your immune system overreacts to an allergen, commonly referred to as a trigger source, which your body detects to be harmful. These can include normal, everyday substances or environmental factors such as: pollen, dust mites, plant extracts, and animal dander.</p>
                <h3 className="mt-5">What causes allergies?</h3> 
                <p>The response your body elicits from the detected allergen, is what causes common allergy symptoms. Once the source is detected, your body will start to produce immune system chemicals called histamines. These histamines are responsible for the degree of allergy symptoms you experience, ranging from mild irritations and moderate illness to anaphylactic shock.</p>
                <h3 className="mt-5">What are the symptoms of seasonal allergies?</h3>
                <p>There are a wide variety of factors that cause seasonal allergies, including your natural immune response to certain allergens and your exposure to environmental factors. Some symptoms include:</p>
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Nasal congestion
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Excessive sneezing
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Itchy or watery eyes
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Chest congestion 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Coughing 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Sore throat
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i>Headache
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h3 className="mt-5">What are the additional risk factors of seasonal allergies?</h3>
                <p>
           Those who suffer from asthma and chronic respiratory conditions are at greater risk for more severe seasonal allergy symptoms. Allergy symptoms can also easily be confused with symptoms of COVID-19 (shortness of breath, coughing, headache, and fatigue). You can rely on us to rule out any uncertainties you may have and properly diagnose your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Strep throat vs. sore throat.</h2>
                <p>
             Throat pain that comes on suddenly usually requires a strep throat test to identify if your sore throat is more than just a viral infection.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SeasonalAllergies;



