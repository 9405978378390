import { Link } from "react-router-dom";
import SomeAdvice from "../../../component/SomeAdvice";

function Covid19Test() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>COVID-19 testing information</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>COVID-19 testing information</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-mission-area ptb-100">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="our-mission-content">
                <h2>COVID-19 testing information</h2>
                <p>
                  RH Medical currently offers three forms of COVID-19 testing at all
                  of our locations.
                </p>
                 <a href="#" className="btn btn-primary mt-4">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="our-mission-image">
                <img src="/assets/img/mission-img1.jpg" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area ptb-100 pt-0 pb-0">
        <div className="container">
          <div className="section-title">
            <h2>The three types of COVID-19 tests.</h2>
          </div>

          <div className="row doctor-details-image text-start">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="main-services-box">
                <h3 className="mt-0">Rapid Test</h3>
                <p>
                  This test will detect if you are actively infected with the
                  COVID-19 virus. Administered via a nasal swab.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="main-services-box">
                <h3 className="mt-0">PCR Test</h3>
                <p>
                  This test will detect if you are actively infected with the
                  COVID-19 virus. Administered via a nasal swab.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="main-services-box">
                <h3 className="mt-0">Antibody Test</h3>
                <p>
                  We offer the Serum Antibody IgG Test and the Spike Protein
                  Antibody Test, which will detect if your body has built up
                  antibodies for the COVID-19 virus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-details-area pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 m-auto">
              <div className="services-details-desc">
                <h1>Questions and answers about COVID-19 testing.</h1>
                <p>
                  Find answers here to some of the most common questions
                  patients have about COVID-19 testing.
                </p>

                <div className="accordion mt-5" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        What do COVID-19 tests detect?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label>
                              {" "}
                              <strong>Rapid and PCR Tests (Nasal Swab)</strong>
                            </label>
                            <p>
                              {" "}
                              Detects if you are actively infected with the
                              COVID-19 virus.
                            </p>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label>
                              {" "}
                              <strong>
                                The Serum Antibody IgG Test (Blood Test)
                              </strong>
                            </label>
                            <p>
                              Detects if you have had prior exposure to COVID-19
                              or a COVID-19 infection and your body has built up
                              antibodies for the virus. For most viral
                              illnesses, a positive antibody test means prior
                              exposure to a virus and possible immunity to
                              future infection. As COVID-19 is a novel (new)
                              infection, it is unclear if a positive antibody
                              test result offers immunity. RH Medical recommends
                              universal precautions (handwashing, social
                              distancing, and masks when appropriate).
                            </p>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label>
                              {" "}
                              <strong>
                                The Spike Protein Antibody Test (Blood Test)
                              </strong>
                            </label>
                            <p>
                              This test potentially identifies antibodies
                              created by you in response to vaccination against
                              the COVID-19 virus. It can also detect antibodies
                              made in response to a COVID-19 infection.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How and when will I get results?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                    >
                      <div className="accordion-body">
                        <strong>
                          This is the second item's accordion body.
                        </strong>{" "}
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                        <code>.accordion-body</code>, though the transition does
                        limit overflow.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How much does each test cost?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                    >
                      <div className="accordion-body">
                        <strong>
                          This is the third item's accordion body.
                        </strong>{" "}
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                        <code>.accordion-body</code>, though the transition does
                        limit overflow.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingfour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        What precautions do I take based on my results?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingfour"
                    >
                      <div className="accordion-body">
                        <strong>
                          This is the third item's accordion body.
                        </strong>{" "}
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                        <code>.accordion-body</code>, though the transition does
                        limit overflow.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading5">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        What platforms are used for testing?
                      </button>
                    </h2>
                    <div
                      id="collapse5"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading5"
                    >
                      <div className="accordion-body">
                        <strong>
                          This is the third item's accordion body.
                        </strong>{" "}
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                        <code>.accordion-body</code>, though the transition does
                        limit overflow.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading6">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        What are my treatment options if I test positive for
                        COVID-19? Is there a medication available?
                      </button>
                    </h2>
                    <div
                      id="collapse6"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading6"
                    >
                      <div className="accordion-body">
                        <strong>
                          This is the third item's accordion body.
                        </strong>{" "}
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                        <code>.accordion-body</code>, though the transition does
                        limit overflow.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading7">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7"
                      >
                        Can I be treated virtually for COVID-19?
                      </button>
                    </h2>
                    <div
                      id="collapse7"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading7"
                    >
                      <div className="accordion-body">
                        <strong>
                          This is the third item's accordion body.
                        </strong>{" "}
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                        <code>.accordion-body</code>, though the transition does
                        limit overflow.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading8">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapse8"
                      >
                        How do I receive virtual care for COVID-19?
                      </button>
                    </h2>
                    <div
                      id="collapse8"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading8"
                    >
                      <div className="accordion-body">
                        <strong>
                          This is the third item's accordion body.
                        </strong>{" "}
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                        <code>.accordion-body</code>, though the transition does
                        limit overflow.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Covid19Test;
