import { Link } from "react-router-dom";
import SomeAdvice from "../../../component/SomeAdvice";


function Illnesses() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Illnesses</h2>
                <ul>
                 <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>Illnesses</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Illnesses</h1>
                <p>
                  When you're not feeling well due to an illness, we'll provide
                  the fast, quality care you need to get back on track. Learn
                  more about urgent care services RH Medical provides.
                </p>
                <h3>Conditions we treat.</h3>
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Allergies
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Allergic reactions
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>
                          <Link className="read-more-btn-service" to={'/services/illnesses/asthma-treatment'}>Bronchitis,
                          wheezing and asthma attacks</Link>
                          
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Congestion and
                          upper respiratory infections
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Cough,
                          <Link className="read-more-btn-service" to={'/services/illnesses/cold-flu'}>cold and
                          flu</Link>, <Link className="read-more-btn-service" to={'/services/covid-19-testing'}>COVID-19</Link>
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Ear infections,
                          earaches and ear wax removal
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Fever
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Migraines and
                          headaches
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Nausea, diarrhea,
                          vomiting and stomach flu
                        </li>

                        <li>
                          <i className="flaticon-check-mark"></i> Nose bleeds
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Pink eye
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Rashes, insect
                          bites and <Link className="read-more-btn-service" to={'/services/illnesses/skin-conditions'}>skin conditions</Link>
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> <Link className="read-more-btn-service" to={'/services/illnesses/seasonal-allergies'}>Seasonal allergies</Link>
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Sinus infection
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Sore throat and
                          strep throat
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Urinary tract
                          infections
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p>...and more.</p>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_services_list">
                  <h3 className="widget-title">More Services</h3>

                  <ul>
                    <li>
                      <a href="single-services.html" className="active">
                        Cancer Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Liver Transplant{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Kidney Cancer{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Cardiac Arrhythmia{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Dental Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                  </ul>
                </section>

                <section className="widget widget_appointment">
                  <h3 className="widget-title">Get Some Advice?</h3>
                 <SomeAdvice />
                </section>
              </aside>
            </div> */}
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                <h2>Cold vs. flu.</h2>
                <p>
                  It's not always easy to tell the difference between a case of
                  the flu and the common cold based on the symptoms alone. Learn
                  about what your symptoms could mean and how RH Medical can help.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Illnesses;
