import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function ColdAndFlu() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Cold and flu</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/illnesses">
Illnesses </Link> </li>
                  <li> Cold and flu  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Cold and flu</h1>
                <p>
             Wake up this morning with a telltale scratchy throat, persistent headache, or fever? You might be coming down with the common cold or the flu. RH Medical is here to help combat your illness, provide a diagnosis, and navigate treatment to get you back to your daily life, fast.
                </p>
                <h3 className="mt-5">Understand your symptoms.</h3>
                <p>The flu, a potentially serious respiratory illness, often begins the same way a cold does. But the symptoms progress much more rapidly. The flu shot is your best defense against the flu, but even if you've had the flu shot, you should see a physician if you are experiencing any flu-like symptoms described below, especially if they're severe.</p>
                <p className="mt-5">Common cold symptoms:</p> 
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Sore throat
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Runny nose
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Sneezing
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Nasal congestion
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Mucous discharge (yellow or green)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Cough
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Mild body aches
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Overall feeling of malaise
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className="mt-5">
             Flu Symptoms:
                </p>
                  <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Sore throat
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Nasal congestion
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Shortness of breath
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Fever over 100.4F (38C)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Body aches
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Chills and sweats
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Headache
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Dry cough 
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Fatigue
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Weakness
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Tips to prevent the flu.</h2>
                <p>
             Advice for staying healthy and well during flu season and all year long.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ColdAndFlu;



