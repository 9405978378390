import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function HIVTesting() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>STD and HIV testing</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/lab-tests-screenings">

Lab tests & screenings </Link> </li>
                  <li> STD and HIV testing  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>STD and HIV testing</h1>
                <p>
             You may worry about taking time off of work or school to get tested for HIV or an STD, and you may even feel embarrassed. Your life doesn’t stop just because you have a personal problem. But if you don’t take care of your health needs, you can’t live your life to the fullest.
                </p>
                <h3 className="mt-5">Confidential and private STD & HIV testing.</h3>
                <p>At RH Medical, we offer fast walk-in STD testing for HIV and other sexually transmitted diseases (STDs) in a safe, non-judgmental environment. Because we’re open late and on weekends, you can visit at the time that works best for you. We won’t make you wait, and you’ll have your results as quickly as possible. We’ll treat your illness and refer you to a specialist if necessary.</p>
                <h3 className="mt-5">Early symptoms of STD & HIV.</h3>
                <p>If you have any of the following symptoms, walk in to any of our locations throughout Long Island, upstate New York or NYC for STD testing and treatment.</p>
                
                <h3 className="mt-5">Symptoms of STDs in Women:</h3>
                <p>In women, symptoms of common STDs may include pain or burning during urination, abnormal vaginal discharge, bloody vaginal discharge, pain in the abdomen, spotting, itching, blisters, fever, swollen glands, open sores, and warts. Some STDs, like chlamydia, and gonorrhea cause no symptoms. If your sexual partner has been diagnosed with an STD, testing yourself is vital to prevent complications such as infertility and certain types of cancer. </p>
                 <h3 className="mt-5">Symptoms of STDs in Men:</h3>
                <p>Men with STDs may experience discharge from the urethra, pain during urination, inflammation of the testicles or prostate, fever, or sores. Untreated STDs put you at risk for organ failure, infertility, and even brain damage. If you have an STD, your sexual partners are at risk of transmission, so the only way to protect them is to refrain from sexual activity until diagnosis and treatment are completed. </p>
                 <h3 className="mt-5">Walk-in STD testing services.</h3>
                <p>We provide comprehensive urgent care services for STDs such as: Rapid HIV, Gonorrhea, Chlamydia, Hepatitis B and C, Herpes, Syphilis, Trichomonas.  </p>
                <p>For immediate, fast, and comprehensive STD testing and treatment, walk into one of our urgent care clinics today.</p>
                
              </div>
            </div>

          
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Understanding STD and HIV symptoms.</h2>
                <p>
             Our team will help you understand the common symptoms of sexually transmitted diseases, and offer testing services that can help diagnose these infections.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HIVTesting;



