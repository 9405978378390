import { Link } from "react-router-dom";

function Services() {

    return (
        <>
             <section className="page-title-area page-title-bg4">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Our Services</h2>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </section>
             <section className="services-area ptb-100 bg-f4f9fd">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-1">
                            <div className="icon">
                                <i className="flaticon-cancer"></i>
                            </div>
                            <h3><Link to="/services/injuries">Injuries</Link></h3>
                            <p>Sprains and strains, cuts, bruises, or other accidents. Make sure you get them checked out right away.</p>

                            <Link to="/services/injuries" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-2">
                            <div className="icon">
                                <i className="flaticon-liver"></i>
                            </div>
                            <h3><Link to="/services/illnesses">Illnesses</Link></h3>
                            <p>Sore throats, allergies, COVID-19, or the flu. We’ll tell you what it is—and get you feeling better.</p>

                            <Link to="/services/illnesses" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-3">
                            <div className="icon">
                                <i className="flaticon-kidney"></i>
                            </div>
                            <h3><Link to="/services/x-rays">X-rays</Link></h3>
                            <p>At every RH Medical, you can count on an advanced digital X-rays for fast and accurate answers.</p>

                            <Link to="/services/x-rays" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-4">
                            <div className="icon">
                                <i className="flaticon-ekg"></i>
                            </div>
                            <h3><Link to="/services/lab-tests-screenings">Lab tests & screenings</Link></h3>
                            <p>Stop by any of our locations for tests and screenings to get the results you need quickly.</p>

                            <Link to="/services/lab-tests-screenings" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-5">
                            <div className="icon">
                                <i className="flaticon-tooth"></i>
                            </div>
                            <h3><Link to="/services/vaccinations">Vaccinations</Link></h3>
                            <p>We offer a wide range of vaccines and immunizations, from the seasonal flu to travel vaccines.</p>

                            <Link to="/services/vaccinations" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-6">
                            <div className="icon">
                                <i className="flaticon-radiation"></i>
                            </div>
                            <h3><Link to="/services/on-the-job-injuries">On-the-job injuries</Link></h3>
                            <p>If employees get hurt on the job, we’ll be ready to quickly treat their injuries and get them back to work. </p>

                            <Link to="/services/on-the-job-injuries" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-7">
                            <div className="icon">
                                <i className="flaticon-brain"></i>
                            </div>
                            <h3><Link to="/services/womens-health">Women’s health</Link></h3>
                            <p>Trained and experienced in treating women's health issues, so you can get answers and the right treatment fast.</p>

                            <Link to="/services/womens-health" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-8">
                            <div className="icon">
                                <i className="flaticon-care"></i>
                            </div>
                            <h3><Link to="/services/pediatric-care">Pediatric care</Link></h3>
                            <p>Our doctors are well versed in pediatric urgent care, so your child can get treated even when your pediatrician isn’t available.</p>

                            <Link to="/services/pediatric-care" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box bg-9">
                            <div className="icon">
                                <i className="flaticon-dental-chair"></i>
                            </div>
                            <h3><Link to="/occupational-medicine">Occupational medicine</Link></h3>
                            <p>If you get hurt at work, we’re here to assess and treat you, so you can get back to the job quickly.</p>

                            <Link to="/occupational-medicine" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>

                    {/* <div className="col-lg-12 col-md-12">
                        <div className="pagination-area">
                            <a href="#" className="prev page-numbers"><i className="fas fa-angle-double-left"></i></a>
                            <a href="#" className="page-numbers">1</a>
                            <span className="page-numbers current" aria-current="page">2</span>
                            <a href="#" className="page-numbers">3</a>
                            <a href="#" className="page-numbers">4</a>
                            <a href="#" className="next page-numbers"><i className="fas fa-angle-double-right"></i></a>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
   </>
  );
}

export default Services;
