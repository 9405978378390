import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function SeasonalFluShots() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Seasonal flu shots</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/vaccinations">

Vaccinations  </Link> </li>
                  <li> Seasonal flu shots  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Seasonal flu shots</h1>
                <p>
           To help protect you against the seasonal flu, we are now offering flu shots at all RH Medical locations. This flu shot is preservative free, with no mercury, and is safe for use in everyone over the age of six months, including pregnant women.
                </p>
                <h3 className="mt-5">Flu shots are now available at RH Medical.</h3>
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Regular Dose: For 3 months old to 64 years old, Quadrivalent
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> High Dose: For 65 years old and above, Quadrivalent (Please call your location ahead of visit to confirm availability) 
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p>If you are an insured patient, there is no co-payment for the flu vaccine.</p>
                <p>If you are an uninsured patient, see cost below: </p>
                 <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i>Regular Dose: $30
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> High Dose: $65
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p>As always, we recommend to check with your insurance carrier if receiving a flu shot at RH Medical is covered by your insurance plan.</p>
              
                 <h2 className="mt-5">Flu shot FAQs.</h2>
 <div className="accordion mt-5" id="accordionExample123">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading1">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        aria-expanded="false"
                        aria-controls="collapse1"
                      >
                        Why get the flu shot?
                      </button>
                    </h2>
                    <div
                      id="collapse1"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading1"
                    >
                      <div className="accordion-body">
                           Flu shots save lives. For healthy people, the flu may be a nuisance, so the shot can reduce your risk of getting ill and losing time from work or school. If you have health issues, the shot can actually reduce your risk for more serious complications such as pneumonia, hospitalization, and death. In addition, by reducing your risk you can help prevent the spread of flu to other vulnerable people who may be at risk for complications, such as babies and the elderly.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading2">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                      >
                       When should I get the flu shot?
                      </button>
                    </h2>
                    <div
                      id="collapse2"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading2"
                    >
                      <div className="accordion-body">
                       In short, it's best to get your flu shot before flu season begins and definitely before it peaks in the winter months (between December and February). Experts recommend that people  get their flu shot between September and October since the virus can start circulating in late fall.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading3">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        Who should get the flu shot?
                      </button>
                    </h2>
                    <div
                      id="collapse3"
                      className="accordion-collapse collapse"
                      aria-labelledby="heading3"
                    >
                      <div className="accordion-body">
                      Everyone 6 months and older should get the flu vaccine, especially those who are at high risk of developing complications from the flu. If you are not feeling well, have an allergy to the vaccine, or ever had Guillain-Barre syndrome, you should talk to your doctor about getting the flu shot. Not only does the flu vaccine help prevent widespread illness and limits community spread, but it also protects our most vulnerable populations from illness.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Tips to prevent the flu.</h2>
                <p>
           Advice for staying healthy and well during flu season and all year long.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SeasonalFluShots;



