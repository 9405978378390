import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Preloader from "./component/Preloader";
import Root from "./Root";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Injuries from "./pages/ServiceDetails/Injuries";
import Illnesses from "./pages/ServiceDetails/Illnesses";
import WomensHealth from "./pages/ServiceDetails/WomensHealth";
import PediatricCare from "./pages/ServiceDetails/PediatricCare";
import Xrays from "./pages/ServiceDetails/Xrays";
import Vaccinations from "./pages/ServiceDetails/Vaccinations";
import LabTestsScreenings from "./pages/ServiceDetails/LabTestsScreenings";
import Covid19Test from "./pages/ServiceDetails/Covid19Test";
import JobInjuries from "./pages/ServiceDetails/JobInjuries";
import NotFound from "./pages/404";
import TickBites from "./pages/ServiceDetails/Injuries/TickBites";
import WoundCare from "./pages/ServiceDetails/Injuries/WoundCare";
import AsthmaTreatment from "./pages/ServiceDetails/Illnesses/AsthmaTreatment";
import ColdAndFlu from "./pages/ServiceDetails/Illnesses/ColdAndFlu";
import SkinConditions from "./pages/ServiceDetails/Illnesses/SkinConditions";
import SeasonalAllergies from "./pages/ServiceDetails/Illnesses/SeasonalAllergies";
import UtiTesting from "./pages/ServiceDetails/WomensHealth/UtiTesting";
import SeasonalFluShots from "./pages/ServiceDetails/Vaccinations/SeasonalFluShots";
import TravelVaccinations from "./pages/ServiceDetails/Vaccinations/TravelVaccinations";
import HIVTesting from "./pages/ServiceDetails/LabTestsScreenings/HIVTesting";
import Faqs from "./pages/Faq";
import Insurance from "./pages/Insurance";
import ContactUs from "./pages/ContactUs";
import "./App.css";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      // errorElement: <div>Fds</div>,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/services/injuries",
          element: <Injuries />,
        },
        {
          path: "/services/injuries/tick-bites",
          element: <TickBites />,
        },
        {
          path: "/services/injuries/wound-care",
          element: <WoundCare />,
        },
        {
          path: "/services/illnesses",
          element: <Illnesses />,
        },
        {
          path: "/services/illnesses/asthma-treatment",
          element: <AsthmaTreatment />,
        },
        {
          path: "/services/illnesses/cold-flu",
          element: <ColdAndFlu />,
        },
        {
          path: "/services/illnesses/skin-conditions",
          element: <SkinConditions />,
        },
        {
          path: "/services/illnesses/seasonal-allergies",
          element: <SeasonalAllergies />,
        },
        {
          path: "/services/womens-health",
          element: <WomensHealth />,
        },
        {
          path: "/services/womens-health/uti-testing",
          element: <UtiTesting />,
        },
        {
          path: "/services/pediatric-care",
          element: <PediatricCare />,
        },
        {
          path: "/services/x-rays",
          element: <Xrays />,
        },
        {
          path: "/services/vaccinations",
          element: <Vaccinations />,
        },
        {
          path: "/services/vaccinations/seasonal-flu-shots",
          element: <SeasonalFluShots />,
        },
        {
          path: "/services/vaccinations/travel-vaccinations-immunizations",
          element: <TravelVaccinations />,
        },
        {
          path: "/services/lab-tests-screenings",
          element: <LabTestsScreenings />,
        },
        {
          path: "/services/lab-tests-screenings/std-hiv-testing",
          element: <HIVTesting />,
        },
        {
          path: "/services/covid-19-testing",
          element: <Covid19Test />,
        },
        {
          path: "/services/on-the-job-injuries",
          element: <JobInjuries />,
        },

        {
          path: "/insurance",
          element: <Insurance />,
        },
        {
          path: "/faqs",
          element: <Faqs />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },

        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return (
    <>
      {/* <Preloader /> */}
      <RouterProvider router={router} />
    </>
  );
}

export default App;
