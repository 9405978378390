import { Helmet } from "react-helmet-async";
import { Link, NavLink } from "react-router-dom";


function Header() {


  return (
    <>
       <Helmet>
        <script src="/assets/js/menu.js" />
      </Helmet>
      <header className="header-area">
        <div className="top-header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <ul className="header-contact-info">
                  <li>
                    <i className="far fa-clock"></i> Mon - Fri 09:00 AM - 05:30 PM
                  </li>
                  <li>
                    <i className="fas fa-phone"></i> Call Us:{" "}
                    <a href="#">+1 718-530-0051</a>
                  </li>
                  <li>
                    <i className="far fa-paper-plane"></i>{" "}
                    <a href="#">
                      <span
                        className="__cf_email__"
                      >
                       rhmedicaluc@gmail.com
                      </span>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4">
                <div className="header-right-content">
                  <ul className="top-header-social">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100063785340628" target={'_blank'}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.instagram.com/rhmedicalurgentcare/" target={'_blank'}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>

                  {/* <div className="lang-select">
                    <select>
                      <option>Eng</option>
                      <option>Spa</option>
                      <option>Ara</option>
                      <option>Fre</option>
                    </select>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Start Navbar Area --> */}
        <div className="navbar-area">
          <div className="fovia-responsive-nav">
            <div className="container">
              <div className="fovia-responsive-menu">
                <div className="logo">
                  <Link to={'/'}>
                    <img src="/assets/img/logo.png" alt="logo" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="fovia-nav">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                 <Link className="navbar-brand" to={'/'}>
                  <img src="/assets/img/logo.png" alt="logo" />
                </Link>

                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink to="/" className="nav-link" activeclassname="active">
  Home
</NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink to="/services" className="nav-link"  activeclassname="active">
                        Services <i className="fas fa-plus"></i>
                      </NavLink>
                      <ul className="dropdown-menu w-500">
                        <div className="row"> 
                        <div className=" col-md-12 col-lg-6"> 
                        <li className="nav-item">
                          <NavLink to="/services/injuries" className="nav-link" activeclassname="active">
                           Injuries
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink to="/services/illnesses" className="nav-link" activeclassname="active">
                            Illnesses
                          </NavLink>
                            </li>
                             <li className="nav-item">
                          <NavLink to="/services/womens-health" className="nav-link" activeclassname="active">
                           Women's health
                            </NavLink>
                            </li>
                             <li className="nav-item">
                          <NavLink to="/services/pediatric-care" className="nav-link" activeclassname="active">
                          Pediatric care
                            </NavLink>
                            </li>
                             <li className="nav-item">
                          <NavLink to="/services/x-rays" className="nav-link" activeclassname="active">
                           X-rays
                            </NavLink>
                        </li>
                          </div>
                          <div className="col-md-12 col-lg-6"> 
                        <li className="nav-item">
                          <NavLink to="/services/vaccinations" className="nav-link" activeclassname="active">
                           Vaccinations
                            </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink to="/services/lab-tests-screenings" className="nav-link" activeclassname="active">
                           Lab tests & screenings
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink to="/services/covid-19-testing" className="nav-link" activeclassname="active">
                            COVID-19 testing
                          </NavLink>
                            </li>
                             <li className="nav-item">
                          <NavLink to="/services/on-the-job-injuries" className="nav-link" activeclassname="active">
                          On-the-job injuries
                            </NavLink>
                            </li>
                             {/* <li className="nav-item">
                          <NavLink to="/occupational-medicine" className="nav-link" activeclassname="active">
                          Occupational Medicine
                            </NavLink>
                            </li> */}
                          </div>
                        </div>
                        
                         <li className="nav-item" style={{borderTop : '1px solid #eeeeee'}}>
                     <NavLink to="/services" className="nav-link" activeclassname="active">
                           All Services  <i className="fas fa-arrow-right"></i>
                          </NavLink>
                          </li>
                
                      </ul>
                      
                    </li>

                    <li className="nav-item">
                     <NavLink to="/insurance" className="nav-link" activeclassname="active">
                          Insurance
                            </NavLink>
                        
                        {/* <i className="fas fa-plus"></i> */}
                      {/* <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="doctor.html" className="nav-link">
                            Doctor
                          </a>
                        </li>

                        <li className="nav-item">
                          <a href="doctor-details.html" className="nav-link">
                            Doctor Details
                          </a>
                        </li>
                      </ul> */}
                    </li>

                    <li className="nav-item">
                      <NavLink to="/about" className="nav-link"  activeclassname="active">
                        About us <i className="fas fa-plus"></i>
                      </NavLink>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <NavLink to="/about" className="nav-link"  activeclassname="active">
                              About RH Medical
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink to="/careers"className="nav-link" activeclassname="active">
                            Join our team
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink to="/fellowship"className="nav-link" activeclassname="active">
                           fellowship
                          </NavLink>
                        </li>

                        {/* <li className="nav-item">
                          <a href="checkout.html" className="nav-link">
                            Checkout
                          </a>
                        </li> */}
                      </ul>
                    </li>

                    {/* <li className="nav-item">
                      <a href="#" className="nav-link">
                        Blog <i className="fas fa-plus"></i>
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="blog-1.html" className="nav-link">
                            Blog Grid
                          </a>
                        </li>

                        <li className="nav-item">
                          <a href="blog-2.html" className="nav-link">
                            Blog Right Sidebar
                          </a>
                        </li>

                        <li className="nav-item">
                          <a href="single-blog.html" className="nav-link">
                            Blog Details
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        Pages <i className="fas fa-plus"></i>
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="about.html" className="nav-link">
                            About
                          </a>
                        </li>

                        <li className="nav-item">
                          <a href="appointment.html" className="nav-link">
                            Appointment
                          </a>
                        </li>

                        <li className="nav-item">
                          <a href="timetable.html" className="nav-link">
                            Time Table
                          </a>
                        </li>

                        <li className="nav-item">
                          <a href="partner.html" className="nav-link">
                            Partner
                          </a>
                        </li>

                        <li className="nav-item">
                          <a href="error-404.html" className="nav-link">
                            404 Error
                          </a>
                        </li>

                        <li className="nav-item">
                          <a href="faq.html" className="nav-link">
                            FAQ
                          </a>
                        </li>
                      </ul>
                    </li> */}

                    <li className="nav-item">
                      <NavLink to="contact-us" className="nav-link"  activeclassname="active">
                       Contact Us
                      </NavLink>
                    </li>
                  </ul>

                  <div className="others-options">
                    {/* <a href="cart.html" className="cart-btn">
                      <i className="flaticon-shopping-bag"></i>
                    </a> */}

                    <div className="option-item">
                      <i className="search-btn fas fa-search"></i>
                      <i className="search-close-btn fas fa-times"></i>

                      <div className="search-overlay search-popup">
                        <div className="search-box">
                          <form className="search-form">
                            <input
                              className="search-input"
                              name="search"
                              placeholder="Search"
                              type="text"
                            />

                            <button className="search-button" type="submit">
                              <i className="fas fa-search"></i>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>

                    <NavLink to="contact-us" className="btn btn-primary">
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {/* <!-- End Navbar Area --> */}
      </header>
    </>
  );
}

export default Header;
