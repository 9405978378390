import { Link } from "react-router-dom";
import SomeAdvice from "../../../component/SomeAdvice";

function WomensHealth() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Women's health</h2>
                <ul>
                 <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>Women's health</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Women's health</h1>
                <p>
                  When you are experiencing a women's health issue and prefer not to wait weeks to see your OB/GYN, stop by one of our locations. Our experts can treat a variety of gynecologic issues, so you can get answers and the right treatment fast.
                </p>
                <h3>Women’s health conditions we treat.</h3>
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Abdominal/pelvic pain or cramping
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Abnormal menstruation
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Hemorrhoids & abscess
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> IV fluids for dehydration
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Morning sickness
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Menopausal symptoms
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Testing for pregnancy
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Referrals for ultrasound
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Referrals to board-certified OB/GYN and reproductive health specialists
                        </li>

                        <li>
                          <i className="flaticon-check-mark"></i>
                           <Link className="read-more-btn-service" to={'/services/womens-health/uti-testing'}>Urinary tract infections (UTIs) and other urinary issues</Link>
                          
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Vaginal complaints (i.e. bleeding, discharge, itchiness, dryness)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Yeast infections
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_services_list">
                  <h3 className="widget-title">More Services</h3>

                  <ul>
                    <li>
                      <a href="single-services.html" className="active">
                        Cancer Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Liver Transplant{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Kidney Cancer{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Cardiac Arrhythmia{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Dental Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                  </ul>
                </section>

                <section className="widget widget_appointment">
                  <h3 className="widget-title">Get Some Advice?</h3>
                 <SomeAdvice />
                </section>
              </aside>
            </div> */}
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                <h2>Understanding STD and HIV symptoms.</h2>
                <p>
                 Understand the common symptoms of sexually transmitted diseases, and learn about testing services that can help diagnose these infections.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WomensHealth;
