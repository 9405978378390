import { Link } from "react-router-dom";
import SomeAdvice from "../../../../component/SomeAdvice";


function AsthmaTreatment() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Asthma treatment</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li> <Link to="/services/illnesses">
Illnesses </Link> </li>
                  <li> Asthma treatment  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Asthma treatment</h1>
                <p>
              Our board certified doctors are trained in urgent asthma care, including asthma attacks, for both adults and children. With our short wait times, broad insurance/payment options, and comprehensive follow-ups care, RH Medical provides an exceptional experience and a more convenient alternative to the emergency room.
                </p>
                <h3 className="mt-5">Asthma services include:</h3>
                   <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Oxygen saturation evaluation
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Rapid evaluation by medical professional (average wait time of 6 minutes)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Nebulizer treatment
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Oral and/or injectable steroid treatment 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> New or refill prescriptions for inhaler, nebulizer solution, steroid medications, and maintenance medications
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Communication and coordination with your PCP and/or Pulmonologist (or referral to PCP upon request if you do not have one)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p>
              Please note: For asthma diagnosis, please visit your primary care physician.
                </p>
                <h3 className="mt-5">Asthma diagnosis.</h3>
                <p>
            Asthma is a common lung condition that can affect both children and adults. The general symptoms are inflamed pulmonary airways that become constricted and produce mucus. This results in coughing, wheezing and difficulty in breathing.
                </p>
                <p>
                  How serious is asthma? The symptoms range in severity…from a minor cough and wheeze to a life-threatening difficulty of breathing, which would require immediate emergency ICU care. The good news is that most asthmatic symptoms can be controlled with certain lifestyle modifications that can help patients avoid asthma triggers – as well as the regular use of chronic maintenance medications and/or rescue medications.
                </p>
                <h3 className="mt-5">What can cause an asthma attack?</h3>
                <p>
              Common triggers of asthma include:
                </p>
                  <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Smoke (tobacco, fire)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Allergens (dust mites, pet dander, pollen, mold, cockroach allergen)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Outdoor air pollution
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Strenuous physical exercise 
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Extreme temperatures (very cold or very hot and humid)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Food allergies
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Severe emotional conditions
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Complications of influenza
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Complications of pneumococcal infection
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Dietary sulfites
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>


                 <h3 className="mt-5">Early symptoms of asthma.</h3>
                <p>
              Signs and symptoms of asthma in adults:
                </p>
                  <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Wheezing (a high-pitched, musical sound produced when air is forced through narrow airways)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Dry cough (does not produce sputum)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                  <p>
              These symptoms are especially common after a known trigger occurs.
                </p>
<p className="mt-5">
              Signs and symptoms of asthma in children:
                </p>

                 <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Wheezing (a high-pitched, musical sound produced when air is forced through narrow airways)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> A nighttime dry cough (does not produce sputum)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> A cough that recurs seasonally (especially allergen like dust pollen, and mold)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> A cough in response to temperature exposures (especially cold air or hot humid air)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> A cough that cccurs due to exercise, emotional upset, or laughing
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> A cough that lasts more than 3 weeks
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                  <p>
             These symptoms are especially common after a known trigger occurs.
                </p>

                   <h3 className="mt-5">Treatment and prevention of asthma.</h3>
                <p>
            There are ways to control asthma. If you take your prescribed medications regularly and avoid or limit interactions with the common triggers of asthma, your symptoms can be lessened or even contained. However, it is important to take your maintenance asthma medication as prescribed, even though you experience no symptoms and feel fine.
                </p>
                <p className="mt-3">
                  Common asthma treatments:
                </p>
                  <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Inhaler
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Nebulizer
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Steroid inhaler
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Leukotriene inhibitor
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Oral steroids
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                 <h3 className="mt-5">Severe asthma: symptoms that require ER care.</h3>
                <p>
            Asthma can be a serious condition. When symptoms escalate and become severe, they can turn life threatening and will require immediate ER treatment. 
                </p>
                <p className="mt-3">
                 Please call 911 if you experience the following:
                </p>
                  <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Severe asthma attack
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Previous history of severe asthma with hospitalizations, intubations, or ICU admissions
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Blue lips or blue nail deds
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Child seems to be using abdominal muscles to breathe (abdominal breathing) or the lower ribs seem to retract inward (rib retraction)
                        </li>
                         <li>
                          <i className="flaticon-check-mark"></i> Unable to speak in more than one or two word sentences
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Somnolence, confusion or any altered mental status
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Do I have a sinus infection?</h2>
                <p>
              Find out whether your symptoms may be caused by this common condition.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AsthmaTreatment;



