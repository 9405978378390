function Banner() {
  return (
    <>
      <div className="home-slides owl-carousel owl-theme">
        <div className="main-banner item-bg1">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  <span>Best Healing Service</span>
                <h1>Your Health is Our Top Priority</h1>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna Quis
                    ipsumpsum dolor sit amet consectetur.
                  </p>

                  <div className="btn-box">
                    <a href="#" className="btn btn-primary">
                      Make appointment <i className="fas fa-bell"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                      className="btn btn-light popup-youtube"
                    >
                      Play Now <i className="fas fa-play"></i>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="circle-shape1">
            <img src="/assets/img/circle-shape1.png" alt="image" />
          </div>
          <div className="circle-shape2">
            <img src="/assets/img/circle-shape2.png" alt="image" />
          </div>
          <div className="shape1">
            <img src="/assets/img/shape/1.png" alt="image" />
          </div>
        </div>

        <div className="main-banner item-bg2">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  <span>Women Care</span>
                  <h1>Exceptional Care for Women</h1>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna Quis
                    ipsumpsum dolor sit amet consectetur.
                  </p> */}

                  {/* <div className="btn-box">
                    <a href="#" className="btn btn-primary">
                      Make appointment <i className="fas fa-bell"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                      className="btn btn-light popup-youtube"
                    >
                      Play Now <i className="fas fa-play"></i>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="circle-shape1">
            <img src="/assets/img/circle-shape1.png" alt="image" />
          </div>
          <div className="circle-shape2">
            <img src="/assets/img/circle-shape2.png" alt="image" />
          </div>
          <div className="shape1">
            <img src="/assets/img/shape/1.png" alt="image" />
          </div>
        </div>

        <div className="main-banner item-bg3">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  <span>Health Service</span>
                  <h1>Your Health is Our Top Priority</h1>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna Quis
                    ipsumpsum dolor sit amet consectetur.
                  </p>

                  <div className="btn-box">
                    <a href="#" className="btn btn-primary">
                      Make appointment <i className="fas fa-bell"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                      className="btn btn-light popup-youtube"
                    >
                      Play Now <i className="fas fa-play"></i>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="circle-shape1">
            <img src="/assets/img/circle-shape1.png" alt="image" />
          </div>
          <div className="circle-shape2">
            <img src="/assets/img/circle-shape2.png" alt="image" />
          </div>
          <div className="shape1">
            <img src="/assets/img/shape/1.png" alt="image" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
