import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import AWS_SES from "../../common/mail";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

function ContactUs() {
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");


  const sendMail = async (name, email, subject, phone, msg) => {
      const html = `<div><strong>Name : </strong> ${name}</div> <br />
                  <div><strong>Email : </strong> ${email}</div> <br />
                  <div><strong>Phone : </strong> ${phone}</div> <br /> 
                  <div><strong>Subject : </strong> ${subject}</div> <br /> 
                  <div><strong>Message : </strong> ${msg}</div>
                  `;
    try {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
      body: JSON.stringify({
        recipientEmailId:process.env.REACT_APP_TO_EMAIL,
          subject:`RH Medical Urgent Care Contact Us`,
          html:html
        })
    }
    const response = await fetch(process.env.REACT_APP_SEND_EMAIL_URL, config)
    if (response.ok) {
       setLoader(false);
        setMessage("your message has been sent successfully");
        formik.resetForm();
        setTimeout(() => {
          setMessage("");
        }, 5000);
    } else {
        throw "email sent failed"
    }
} catch (error) {
        setMessage(error);
        setLoader(false);
        setTimeout(() => {
          setMessage("");
        }, 5000);
    }
    
           
            }

  // const sendMail = (name, email, subject, phone, msg) => {
  //   const html = `<div><strong>Name : </strong> ${name}</div> <br />
  //                 <div><strong>Email : </strong> ${email}</div> <br />
  //                 <div><strong>Phone : </strong> ${phone}</div> <br /> 
  //                 <div><strong>Message : </strong> ${msg}</div>
  //                 `;
  //   let params = {
  //     Source: ` RH Medical Urgent Care <${process.env.REACT_APP_SOURCE_EMAIL}>`,
  //     Destination: {
  //       ToAddresses: [process.env.REACT_APP_TO_EMAIL],
  //     },
  //     ReplyToAddresses: [],
  //     Message: {
  //       Body: {
  //         Html: {
  //           Charset: "UTF-8",
  //           Data: html,
  //         },
  //       },
  //       Subject: {
  //         Charset: "UTF-8",
  //         Data: subject,
  //       },
  //     },
  //   };
  //   var sendPromise = AWS_SES.sendEmail(params).promise();
  //   sendPromise
  //     .then((data) => {
  //       setLoader(false);
  //       setMessage("your message has been sent successfully");
  //       formik.resetForm();
  //       setTimeout(() => {
  //         setMessage("");
  //       }, 5000);
  //     })
  //     .catch((err) => {
  //       console.error(err, err.stack);
  //       setMessage(err.stack);
  //       setLoader(false);
  //       setTimeout(() => {
  //         setMessage("");
  //       }, 5000);
  //     });
  // };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    subject: Yup.string().required("Please select your subject"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email"),
    phone: Yup.string().required("Please enter your number"),
    message: Yup.string().required("Please enter your message"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      sendMail(
        values?.name,
        values?.email,
        values?.subject,
        values?.phone,
        values?.message
      );
    },
  });

  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Contact</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span>Send Message</span>
            <h2>Drop us message for any query</h2>
            <p>If you have an idea, we would love to hear about it.</p>
          </div>

          <div className="row">
            <div className="col-lg-7 col-md-12">
              <div className="contact-form">
                <form id="contactForm" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik?.values?.name}
                          placeholder="Name"
                        />
                        {formik?.errors?.name && formik?.touched?.name ? (
                          <div className="help-block with-errors">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.name}</li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik?.values?.email}
                          placeholder="Email"
                        />
                        {formik?.errors?.email && formik?.touched?.email ? (
                          <div className="help-block  with-errors">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.email}</li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone"
                          id="phone_number"
                          className="form-control"
                          placeholder="Phone"
                          onChange={formik.handleChange}
                          value={formik?.values?.phone}
                        />
                        {formik?.errors?.phone && formik?.touched?.phone ? (
                          <div className="help-block with-errors">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.phone}</li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="subject"
                          id="msg_subject"
                          className="form-control"
                          placeholder="Subject"
                          onChange={formik.handleChange}
                          value={formik?.values?.subject}
                        />
                        {formik?.errors?.subject && formik?.touched?.subject ? (
                          <div className="help-block with-errors">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.subject}</li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="6"
                          placeholder="Your Message"
                          onChange={formik.handleChange}
                          value={formik?.values?.message}
                        ></textarea>
                        {formik?.errors?.message && formik?.touched?.message ? (
                          <div className="help-block with-errors">
                            <ul className="list-unstyled">
                              <li>{formik?.errors?.message}</li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary mt-0"
                          disabled={loader}
                        >
                          Send Message{" "}
                          <i className="flaticon-right-chevron"></i>
                        </button>
                        {loader && (
                          <div
                            className="spinner-border text-danger ms-3"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </div>
                      {message && (
                        <div className="mt-3 text-center h4">{message}</div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-lg-5 col-md-12">
              <div className="contact-info">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <span>Address</span>
                    3706 3rd Ave, Bronx, NY 10456
                  </li>

                  <li>
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <span>Email</span>
                    rhmedicaluc@gmail.com
                    {/* <span className="__cf_email__" ></span> */}
                  </li>

                  <li>
                    <div className="icon">
                      <i className="fas fa-phone-volume"></i>
                    </div>
                    <span>Phone</span>
                    T : 718-530-0051
                    <br />F : 347-269-5569
                    {/* <a href="#">+44 587 154756</a>
                                    <a href="#">+55 5555 14574</a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-map">
          <img src="/assets/img/bg-map.png" alt="image" />
        </div>
      </section>
    </>
  );
}

export default ContactUs;
