import { Link } from "react-router-dom";
import SomeAdvice from "../../../component/SomeAdvice";


function LabTestsScreenings() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Lab tests and screenings</h2>
                <ul>
                 <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>Lab tests and screenings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Lab tests and screenings</h1>
                <p>
                 Need urgent medical lab tests or X-rays? We perform most lab work conveniently on-site, so you can get reliable results without a long wait.
                </p>
                <h3>On-site medical lab tests.</h3>
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Blood draws
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>
                           <Link className="read-more-btn-service" to={'/services/covid-19-testing'}>COVID-19 testing</Link>
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> EKG
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Rapid influenza A&B testing (results in 15 minutes)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Rapid mononucleosis screening (results in 15 minutes)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Rapid strep (results in 5 minutes)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>
                          
                          <Link className="read-more-btn-service" to={'/services/lab-tests-screenings/std-hiv-testing'}>STD & HIV testing </Link>, including Rapid HIV (results in 15 minutes)
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Urine pregnancy test
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Urinalysis
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_services_list">
                  <h3 className="widget-title">More Services</h3>

                  <ul>
                    <li>
                      <a href="single-services.html" className="active">
                        Cancer Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Liver Transplant{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Kidney Cancer{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Cardiac Arrhythmia{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Dental Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                  </ul>
                </section>

                <section className="widget widget_appointment">
                  <h3 className="widget-title">Get Some Advice?</h3>
                 <SomeAdvice />
                </section>
              </aside>
            </div> */}
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                <h2>The benefits of donating blood.</h2>
                <p>
                  Blood donations are essential to saving the lives of surgical patients, trauma victims, cancer patients, and individuals suffering from chronic illness.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LabTestsScreenings;
