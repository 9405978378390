import { Link } from "react-router-dom";


function Insurance() {

  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Insurance</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    Insurance
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Paying with insurance</h1>
                <p>
          We accept most major health insurance plans, including Managed Medicaid, Medicare Advantage, and exchange plans. Get started by finding your plan on our list.
                </p>
                <h2 className="mt-5">Check your coverage.</h2>
                <p>See the specific insurance plans we accept by selecting your state. You can also contact your local RH Medical and we’ll provide coverage answers quickly.</p>
 <div className="accordion mt-3 mb-5" id="accordionExample123">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage1">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coveragecollapse1"
                        aria-expanded="false"
                        aria-controls="coveragecollapse1"
                      >
                       1199 (SEIU)
                      </button>
                    </h2>
                    <div
                      id="coveragecollapse1"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage1"
                    >
                      <div className="accordion-body">
                         1199 (SEIU)
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage2">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage2"
                        aria-expanded="false"
                        aria-controls="collapsecoverage2"
                      >
                      Aetna
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage2"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage2"
                    >
                      <div className="accordion-body">
                     All plans accepted excluding Aetna Better Health (Medicaid) and Aetna NJ Savings EPO
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage3">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage3"
                        aria-expanded="false"
                        aria-controls="collapsecoverage3"
                      >
                       Affinity
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage3"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage3"
                    >
                      <div className="accordion-body">
                      All plans accepted
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage4">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage4"
                        aria-expanded="false"
                        aria-controls="collapsecoverage4"
                      >
                       Agewell
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage4"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage4"
                    >
                      <div className="accordion-body">
                      Medicare
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage5">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage5"
                        aria-expanded="false"
                        aria-controls="collapsecoverage5"
                      >
                       Bright Health
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage5"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage5"
                    >
                      <div className="accordion-body">
                      Managed Medicare
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage6">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage6"
                        aria-expanded="false"
                        aria-controls="collapsecoverage6"
                      >
                       Centivo
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage6"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage6"
                    >
                      <div className="accordion-body">
                     All plans accepted
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage7">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage7"
                        aria-expanded="false"
                        aria-controls="collapsecoverage7"
                      >
                       CIGNA
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage7"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage7"
                    >
                      <div className="accordion-body">
                     All plans accepted excluding Cigna Local Plus
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage8">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage8"
                        aria-expanded="false"
                        aria-controls="collapsecoverage8"
                      >
                       ConnectiCare
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage8"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage8"
                    >
                      <div className="accordion-body">
                    <div className="mb-3">Choice</div>
                    <div className="mb-3">Flex</div>
                    <div className="mb-3">Passage Network</div>
                    
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage9">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage9"
                        aria-expanded="false"
                        aria-controls="collapsecoverage9"
                      >
                       Corvel
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage9"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage9"
                    >
                      <div className="accordion-body">
                   Worker's Compensation
                    
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage10">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage10"
                        aria-expanded="false"
                        aria-controls="collapsecoverage10"
                      >
                       Eldercare
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage10"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage10"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Medicare</div>
                  <div className="mb-3">Medicaid</div>
                    
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage11">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage11"
                        aria-expanded="false"
                        aria-controls="collapsecoverage11"
                      >
                       Emblem Health
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage11"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage11"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Emblem , HIP, GHI - Only Qualcare Network (Qualcare Logo Must Be on Insurance Card)</div>
                  <div className="mb-3">Medicaid</div>
                  <div className="mb-3">Essential Plan (NY State Marketplace Plan)</div>
                  <div className="mb-3">Medicare</div>
                  <div className="mb-3">Dual Plan (Medicare/Medicaid)</div>
                    
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage12">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage12"
                        aria-expanded="false"
                        aria-controls="collapsecoverage12"
                      >
                       Empire Blue Cross Blue Shield
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage12"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage12"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">All plans accepted</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage13">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage13"
                        aria-expanded="false"
                        aria-controls="collapsecoverage13"
                      >
                       Europ Assistance
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage13"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage13"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Travel Insurance</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage14">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage14"
                        aria-expanded="false"
                        aria-controls="collapsecoverage14"
                      >
                       Fidelis
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage14"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage14"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Essential (Exchange)</div>
                  <div className="mb-3">Medicaid</div>
                  <div className="mb-3">Medicare</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage15">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage15"
                        aria-expanded="false"
                        aria-controls="collapsecoverage15"
                      >
                       First Health
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage15"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage15"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">First Health Logo on Card</div>
                  <div className="mb-3">First Health Medicare Advantage</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage16">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage16"
                        aria-expanded="false"
                        aria-controls="collapsecoverage16"
                      >
                       HealthFirst NY
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage16"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage16"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">HealthFirst Medicaid</div>
                  <div className="mb-3">HealthFirst Essential (Exchange)</div>
                  <div className="mb-3">HealthFirst Commercial & PPO</div>
                  <div className="mb-3">HealthFirst Medicare Advantage HMO</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage17">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage17"
                        aria-expanded="false"
                        aria-controls="collapsecoverage17"
                      >
                       Horizon Blue Cross Blue Shield
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage17"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage17"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">All plans accepted (must have Blue Card)</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage18">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage18"
                        aria-expanded="false"
                        aria-controls="collapsecoverage18"
                      >
                       Humana
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage18"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage18"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Humana Medicare Plans only</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage19">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage19"
                        aria-expanded="false"
                        aria-controls="collapsecoverage19"
                      >
                       Independence Care System
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage19"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage19"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">All plans accepted</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage20">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage20"
                        aria-expanded="false"
                        aria-controls="collapsecoverage20"
                      >
                      Magnacare
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage20"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage20"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Magnacare Commercial Plans</div>
                  <div className="mb-3">Worker's Compensation</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage21">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage21"
                        aria-expanded="false"
                        aria-controls="collapsecoverage21"
                      >
                      Medicare
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage21"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage21"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Medicare</div>
                  <div className="mb-3">Railroad Medicare</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage22">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage22"
                        aria-expanded="false"
                        aria-controls="collapsecoverage22"
                      >
                      MetroComp
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage22"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage22"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Worker's Compensation</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage23">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage23"
                        aria-expanded="false"
                        aria-controls="collapsecoverage23"
                      >
                      MetroPlus
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage23"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage23"
                    >
                      <div className="accordion-body">
                  <div className="mb-3"> All plans accepted </div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage24">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage24"
                        aria-expanded="false"
                        aria-controls="collapsecoverage24"
                      >
                      Multiplan
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage24"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage24"
                    >
                      <div className="accordion-body">
                  <div className="mb-3"> Beech Street </div>
                  <div className="mb-3"> Multiplan </div>
                  <div className="mb-3"> PHCS </div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage25">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage25"
                        aria-expanded="false"
                        aria-controls="collapsecoverage25"
                      >
                      MVP Healthcare
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage25"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage25"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Must have Cigna logo </div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage26">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage26"
                        aria-expanded="false"
                        aria-controls="collapsecoverage26"
                      >
                      New York State Worker's Compensation
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage26"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage26"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">New York State Worker's Compensation </div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage27">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage27"
                        aria-expanded="false"
                        aria-controls="collapsecoverage27"
                      >
                      Optum
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage27"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage27"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">VA Community Care Network </div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage28">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage28"
                        aria-expanded="false"
                        aria-controls="collapsecoverage28"
                      >
                      Oscar Health Plan
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage28"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage28"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Oscar NJ Individual & Family Plans (must have Qualcare logo) </div>
                  <div className="mb-3">Oscar NJ PPO Plan (must have Qualcare logo) </div>
                  <div className="mb-3">Oscar NJ Small Group Plans - Exchange (must have Qualcare logo) </div>
                  <div className="mb-3">Oscar Medicare Advantage plans are not accepted </div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage29">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage29"
                        aria-expanded="false"
                        aria-controls="collapsecoverage29"
                      >
                      Oxford Health
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage29"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage29"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Freedom </div>
                  <div className="mb-3">Liberty </div>
                  <div className="mb-3">All Medicare Plans</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage30">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage30"
                        aria-expanded="false"
                        aria-controls="collapsecoverage30"
                      >
                      Partners Health Plan
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage30"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage30"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Medicare Advantage </div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage31">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage31"
                        aria-expanded="false"
                        aria-controls="collapsecoverage31"
                      >
                      The Empire Plan
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage31"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage31"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">All plans accepted excluding Medicaid plans</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage32">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage32"
                        aria-expanded="false"
                        aria-controls="collapsecoverage32"
                      >
                      Three Rivers
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage32"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage32"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">All commercial plans accepted</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage33">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage33"
                        aria-expanded="false"
                        aria-controls="collapsecoverage33"
                      >
                     Tricare
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage33"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage33"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Humana Military (Tricare) - All Products</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage34">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage34"
                        aria-expanded="false"
                        aria-controls="collapsecoverage34"
                      >
                     United Healthcare
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage34"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage34"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">AARP Medicare Complete - All Plans</div>
                  <div className="mb-3">Choice</div>
                  <div className="mb-3">Choice Plus</div>
                  <div className="mb-3">Compas Plans</div>
                  <div className="mb-3">HMO</div>
                  <div className="mb-3">Medicare Advantage Plans</div>
                  <div className="mb-3">Navigate EPO, HMO, POS</div>
                  <div className="mb-3">NYSHIP</div>
                  <div className="mb-3">POS</div>
                  <div className="mb-3">PPO</div>
                  <div className="mb-3">UHC Core</div>
                  <div className="mb-3">VA Community Care Network</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage35">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage35"
                        aria-expanded="false"
                        aria-controls="collapsecoverage35"
                      >
                     Village Care
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage35"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage35"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Village Care</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Insurance;
