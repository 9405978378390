import { Link } from "react-router-dom";

function Faq() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Frequently asked questions</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    Frequently asked questions
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
       <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Frequently asked questions</h1>
                <p>
                  Here are answers to many of the questions we frequently receive. If you don’t see an answer to your question,
                 <Link className="read-more-btn-service" to="/contact-us">contact us</Link> .
                </p>
                <h2 className="mt-5">Services.</h2>
 <div className="accordion mt-3 mb-5" id="accordionExample12345">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage1">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#coveragecollapse1"
                        aria-expanded="false"
                        aria-controls="coveragecollapse1"
                      >
                      Do you perform STD testing?
                      </button>
                    </h2>
                    <div
                      id="coveragecollapse1"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage1"
                    >
                      <div className="accordion-body">
                        <div className="mb-3">Yes, we do STD testing.</div>
                        <div className="mb-3">
                          <Link className="read-more-btn-service" to="/services/lab-tests-screenings">Lab tests & screenings</Link></div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage2">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage2"
                        aria-expanded="false"
                        aria-controls="collapsecoverage2"
                      >
                      Does RH Medical do X-rays?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage2"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage2"
                    >
                      <div className="accordion-body">
                      <div className="mb-3">Yes, each of our locations have X-ray machines on-site, staffed by certified X-ray technicians.</div>
                        <div className="mb-3">
                          <Link className="read-more-btn-service" to="/services/x-rays">X-rays</Link></div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage3">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage3"
                        aria-expanded="false"
                        aria-controls="collapsecoverage3"
                      >
                      Do you do physical exams?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage3"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage3"
                    >
                      <div className="accordion-body">
                      Absolutely. Our doctors regularly provide complete physical exams (routine, for work, sports or school), including blood work.
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage4">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage4"
                        aria-expanded="false"
                        aria-controls="collapsecoverage4"
                      >
                       Do you offer travel vaccines?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage4"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage4"
                    >
                      <div className="accordion-body">
                      <div className="mb-3">Yes. We stock and administer a wide range of travel vaccines.</div>
                        <div className="mb-3">
                          <Link className="read-more-btn-service" to="/services/vaccinations">Vaccinations</Link></div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage5">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage5"
                        aria-expanded="false"
                        aria-controls="collapsecoverage5"
                      >
                       What services do you offer?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage5"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage5"
                    >
                      <div className="accordion-body">
                      <div className="mb-3">From sprained ankles to sore throats: whatever your injury or illness, we’re here to help. Count on us for a full range of services, including physical exams, X-rays, lab tests and screenings, on-the-job injuries, vaccines, women's needs, pediatric care, and more.</div>
                        <div className="mb-3">
                          <Link className="read-more-btn-service" to="/services">See our services</Link></div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage6">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage6"
                        aria-expanded="false"
                        aria-controls="collapsecoverage6"
                      >
                       Do you offer flu shots?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage6"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage6"
                    >
                      <div className="accordion-body">
                      <div className="mb-3">We sure do. Just walk in.</div>
                        <div className="mb-3">
                          <Link className="read-more-btn-service" to="/services/vaccinations">Vaccinations</Link></div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage7">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage7"
                        aria-expanded="false"
                        aria-controls="collapsecoverage7"
                      >
                       Do you treat children?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage7"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage7"
                    >
                      <div className="accordion-body">
                     <div className="mb-3">Absolutely. All of our doctors are either board certified in Emergency Medicine or Family Medicine, and are well experienced in treating children. Many of us are parents, so we understand your parental concerns and will care for your children as we do our own.</div>
                        <div className="mb-3">
                          <Link className="read-more-btn-service" to="/services/pediatric-care">Pediatric care</Link></div>
                      </div>
                    </div>
                  </div>
                </div>

  <h2 className="mt-5">General.</h2>
                 <div className="accordion mt-3 mb-5" id="accordionExample1234">
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage8">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage8"
                        aria-expanded="false"
                        aria-controls="collapsecoverage8"
                      >
                       What is urgent care?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage8"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage8"
                    >
                      <div className="accordion-body">
                    <div className="mb-3">An urgent care practice can supplement the services your primary doctor can provide, from extended office hours and short wait times to a more comprehensive suite of services available on-site than in most primary doctor’s offices (EKG, IV, X-rays, etc.). While your primary doctor cares for multiple chronic medical issues, we treat the problems facing you today. Also, for the cases where your primary physician would have to send you to an ER for a non-life-threatening condition, urgent care can eliminate much of the waiting and expense.</div>
                    <div className="mb-3"><Link className="read-more-btn-service" to="/services">View all services</Link></div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage9">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage9"
                        aria-expanded="false"
                        aria-controls="collapsecoverage9"
                      >
                       What information do I need to be seen by a doctor?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage9"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage9"
                    >
                      <div className="accordion-body">
                   You'll need a valid photo ID and insurance card (if you're paying with insurance). When you visit, you'll complete a short registration form, and then be seen by a doctor.
                    
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage10">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage10"
                        aria-expanded="false"
                        aria-controls="collapsecoverage10"
                      >
                       When should I visit an emergency room instead of RH Medical?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage10"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage10"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">You should visit the emergency room for life and limb threatening conditions such as significant chest pain, shortness of breath, passing out and severely broken bones. Although our offices are staffed by board certified emergency medicine physicians, life threatening emergencies may require hospitalization and the advanced care available only at a hospital. Please call 911 if you are experiencing an emergency. For more information to help you better understand when to go to an ER or Urgent Care Center, please see our Know Where to Go Guide.</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage11">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage11"
                        aria-expanded="false"
                        aria-controls="collapsecoverage11"
                      >
                     Can I make an appointment?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage11"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage11"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">We don't take appointments. Just walk in when you need us.</div>
                    
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage12">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage12"
                        aria-expanded="false"
                        aria-controls="collapsecoverage12"
                      >
                      What are the credentials of RH Medical doctors?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage12"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage12"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">All our doctors are board certified. Most have their certifications in emergency medicine or family medicine. And, many have trained in the best hospitals, winning numerous awards for teaching and training fellow physicians. Alongside our doctors are physician assistants who are also highly trained and qualified.</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage13">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage13"
                        aria-expanded="false"
                        aria-controls="collapsecoverage13"
                      >
                       Do I need a referral or authorization from my primary doctor to visit RH Medical?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage13"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage13"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">No. With our urgent care provider agreements with insurance carriers, you can be treated in our office without a referral.</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage14">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage14"
                        aria-expanded="false"
                        aria-controls="collapsecoverage14"
                      >
                       Why can't I check-in or register online?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage14"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage14"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Each RH Medical has self check-in kiosks. They're fast, simple and convenient. The kiosk will scan your photo ID and automatically enter your information. In fact, our kiosks are the fastest and most accurate way to collect your information. And—once you've signed in at one RH Medical, you'll never have to register at another RH Medical.</div>
                      </div>
                    </div>
                  </div>
                   <div className="accordion-item">
                    <h2 className="accordion-header" id="coverage15">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsecoverage15"
                        aria-expanded="false"
                        aria-controls="collapsecoverage15"
                      >
                      Can I see a doctor now?
                      </button>
                    </h2>
                    <div
                      id="collapsecoverage15"
                      className="accordion-collapse collapse"
                      aria-labelledby="coverage15"
                    >
                      <div className="accordion-body">
                  <div className="mb-3">Absolutely! Just walk in to our locations.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
