import { Link } from "react-router-dom";
import SomeAdvice from "../../../component/SomeAdvice";


function Injuries() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Injuries</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>Injuries</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>Injuries</h1>
                <p>
                At RH Medical, patients can expect efficient and high quality care at a moment's notice. Our doctors, medical assistants, and technicians are trained in all aspects of urgent care medicine for both adults and children.
                </p>
                <h3>Conditions we treat.</h3>
                <div className="services-details-features">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <ul className="services-features-list">
                        <li>
                          <i className="flaticon-check-mark"></i> Abscesses
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Allergic reactions
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Cuts, scrapes & lacerations
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Corneal abrasion and minor eye injuries
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i><Link className="read-more-btn-service" to={'/services/injuries/tick-bites'}> Insect bites & rashes</Link>
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>  Minor fractures & dislocations
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Minor burns
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Minor head injury
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i> Sprains & strains
                        </li>

                        <li>
                          <i className="flaticon-check-mark"></i>Sports injuries
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>
                          <Link className="read-more-btn-service" to={'/services/injuries/wound-care'}> Wound care</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_services_list">
                  <h3 className="widget-title">More Services</h3>

                  <ul>
                    <li>
                      <a href="single-services.html" className="active">
                        Cancer Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Liver Transplant{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Kidney Cancer{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Cardiac Arrhythmia{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Dental Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                  </ul>
                </section>

                <section className="widget widget_appointment">
                  <h3 className="widget-title">Get Some Advice?</h3>
                 <SomeAdvice />
                </section>
              </aside>
            </div> */}
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                 <h2>Sprains vs. strains.</h2>
                <p>
               Sprains and strains are very common injuries that can occur from falling, twisting, lifting, or being involved in an accident. Learn more about the difference between these injuries, and how they’re treated.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Injuries;



