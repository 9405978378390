import { Link } from "react-router-dom";
import SomeAdvice from "../../../component/SomeAdvice";

function Xrays() {
  return (
    <>
      <section className="page-title-area page-title-bg4">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>X-rays</h2>
                <ul>
                 <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>X-rays</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details-area ptb-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-desc">
                <h1>X-rays</h1>
                <p>
                 All RH Medical locations offer on-site X-ray services, so you can get fast and accurate results.
                </p>
                <p>
                  By diagnosing fractures and dislocations, an X-ray gives you the peace of mind of knowing exactly what’s going on with your injury. 
                </p>
                <p>
                  Each of our locations offers digital X-rays for fast and accurate radiographic assessment. We can even transfer your results to a primary care provider or specialist, if you need us to.
                </p>
                <p>
                  X-rays are a basic form of imaging that can help your provider diagnose conditions, such as bone fractures or pneumonia, without any invasive procedures. Having digital X-ray capabilities on-site at every RH Medical allows our team to provide you with faster results and more accurate diagnostics, so that you can get the care you need without any delay.  
                </p>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-12">
              <aside className="widget-area" id="secondary">
                <section className="widget widget_services_list">
                  <h3 className="widget-title">More Services</h3>

                  <ul>
                    <li>
                      <a href="single-services.html" className="active">
                        Cancer Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Liver Transplant{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Kidney Cancer{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Cardiac Arrhythmia{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="single-services.html">
                        Dental Services{" "}
                        <i className="flaticon-arrow-pointing-to-right"></i>
                      </a>
                    </li>
                  </ul>
                </section>

                <section className="widget widget_appointment">
                  <h3 className="widget-title">Get Some Advice?</h3>

                  <SomeAdvice />
                </section>
              </aside>
            </div> */}
          </div>
        </div>
      </section>
      <section className="about-area pt-5 pb-5">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                <h2>Sprains vs. strains.</h2>
                <p>
                 Sprains and strains are very common injuries that can occur from falling, twisting, lifting, or being involved in an accident. Learn more about the difference between these injuries, and how they’re treated.
                </p>
                <a href="#" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Xrays;
