import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Appointment from "../../component/Appointment";
import Banner from "../../component/Banner";
import Blogs from "../../component/Blogs";
import Faqs from "../../component/Faqs";
import FunFacts from "../../component/FunFacts";
import MainServices from "../../component/MainServices";

function Home() {
  return (
    <>
      <Helmet>
        <script src="/assets/js/home.js" />
      </Helmet>
      <Banner />
      <MainServices />
      <section className="about-area ptb-100">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-image">
                <img src="/assets/img/about-img1.jpg" alt="image" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="about-content">
                <span>About Us</span>
                <h2>Short Story About  Clinic</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis.
                </p>

                <ul>
                  <li>
                    <i className="flaticon-check-mark"></i> Scientific Skills
                    For getting a better result
                  </li>
                  <li>
                    <i className="flaticon-check-mark"></i> Communication Skills
                    to getting in touch
                  </li>
                  <li>
                    <i className="flaticon-check-mark"></i> A Career Overview
                    opportunity Available
                  </li>
                  <li>
                    <i className="flaticon-check-mark"></i> A good Work
                    Environment For work
                  </li>
                </ul>

                <Link to="/about" className="btn btn-primary">
                  Learn More <i className="flaticon-right-chevron"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FunFacts />
      <Appointment />
      <Faqs />
      {/* <Blogs /> */}
    </>
  );
}

export default Home;
